interface ImageSize {
    height?: number
    width?: number
}

export function useStoryblokImage(source: string): ImageSize {
    const matches = source.match(/\/(\d+)x(\d+)\//);

    if (!matches) {
        return {
            width: undefined,
            height: undefined,
        };
    }

    return {
        width: Number.parseInt(matches[1], 10),
        height: Number.parseInt(matches[2], 10),
    };
}
